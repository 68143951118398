.st-button {
  height: 32px;
  padding: 0 25px;
  border: none;
  outline: none;
  background-color: rgb(205, 88, 88);
  color: #fff;
  border-radius: 4px;
  margin-left: auto;
  display: block;
  cursor: pointer;

  &:hover {
    background-color: rgb(205, 88, 88);
  }

    &:active {
        background-color: rgb(218, 127, 127);
    }
}
