.st-marker{
    img{
        width: 50px;
        height: 50px;
        object-fit: contain;
    }
}

.st-map{
    width: 80%;
    margin: 0 auto;
    height: 70vh;
    margin-top: 25px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: #00000050 2px 2px 15px;
}